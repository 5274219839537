import Vue from 'vue'
import VueRouter from 'vue-router'
import PageSite from './../components/PageSite';
import PostSite from './../components/PostSite';
import PostsSite from './../components/PostsSite';
import ClientPortal from './../components/ClientPortal';

Vue.use(VueRouter)

const routes = [
    {
        path: '/cms/blog',
        name: 'blogIndex',
        component: PostsSite
    },
    {
        path: '/cms/pages/:slug',
        name: 'page',
        component: PageSite
    },
    {
        path: '/cms/blog/:id/:slug',
        name: 'blog',
        component: PostSite
    },
    {
        path: '*',
        name: 'fallback',
        redirect: { path: '/clients/brewlab'}
    },
    {
        path: '/clients/:slug',
        name: 'client',
        component: ClientPortal
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

<template>
  <b-navbar v-if="$route.name != 'client'">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        WIZ
      </b-navbar-item>
    </template>
    <template slot="start">
        <b-navbar-item :to="{ name: 'page', params: { slug: page.slug }}" tag="router-link" v-for="page in pages" v-bind:key="page.id">
         {{ page.title }}
        </b-navbar-item>
        <b-navbar-item :to="{ name: 'blogIndex'}" tag="router-link" >
        Blog
        </b-navbar-item>

                    <!-- <router-link :to="{ name: 'page', params: { slug: page.slug }}" class="nav-link">{{page.title}}</router-link> -->

      <!-- <b-navbar-item href="#"> Home </b-navbar-item>
      <b-navbar-item href="#"> Documentation </b-navbar-item>
      <b-navbar-dropdown label="Info">
        <b-navbar-item href="#"> About </b-navbar-item>
        <b-navbar-item href="#"> Contact </b-navbar-item>
      </b-navbar-dropdown> -->
    </template>

    <div class="container">
      <a href="/" class="navbar-brand">CMS Blog</a>
      <ul class="navbar-nav mr-auto">
        
      </ul>
    </div>
  </b-navbar>
</template>

<script>
import { getPages } from "./../service";

import Vue from "vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);

export default {
  name: "TopNav",
  data: () => {
    return {
      pages: null,
    };
  },
  created: function () {
    const component = this;

    async function loadPages() {
      try {
        const result = await getPages();

        component.pages = result.pages;
      } catch (ex) {
        component.pages = [];
      }
    }

    loadPages();
  },
};
</script>

<style scoped>
.nav-link.router-link-active {
  color: white !important;
}
</style>
    
<!-- <div v-if="client" class="container header" v-bind:style="` background-color: ${client.mainBackgroundColor};`">
  <div class="block" v-if="client">
    <img v-bind:alt="client.title +' logo'" width="250" v-bind:src="client.logo" /><br/>
    <b-button type="is-info" icon-left="thumbs-up" icon-pack="fas">Like 1.2K</b-button>
    
      <span v-if="client.facebookLikeCode" v-html="client.facebookLikeCode"></span> 
    <p v-if="client.tagline">{{client.tagline}}</p>

  </div>
  
</div> -->
<template>
  <div class="hello">
    <div class="hero is-fullheight is-primary has-background" v-if="client" :style="client.backgroundClass">
      <img
        alt="background"
        class="hero-background is-transparent"
        :src="client.heroBackground"
      />
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            <img
              v-bind:alt="client.title + ' logo'"
              width="250"
              v-bind:src="client.logo"
            /><br />
          </h1>
          <h3 class="subtitle">{{ client.tagline }}</h3>
          <b-button
            type="is-info"
            class="gap"
            icon-left="thumbs-up"
            icon-pack="fas"  style="margin-bottom:15px"
            @click="surfNet(1)" :loading="like_loading"
            >Like {{ client.facebookLikeCount | toHumanString }}</b-button
          >
              <b-carousel :has-drag="true" v-if="hasSetting('banners')">
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <!-- <section :class="`hero is-medium is-${carousel.color}`">
          <div class="hero-body has-text-centered">
            <h1 class="title">{{ carousel.text }}</h1>
          </div>
        </section> -->
        <span class="image">
                 <img :src="getImgUrl(carousel.image)" v-bind:alt="carousel.text">

            </span>
      </b-carousel-item>
    </b-carousel>
         
          <div class="box" style="margin-top:20px;opacity:0.93" v-if="hasSetting('aohang')">
  <article class="media">
    <div class="media-left">
       <figure class=" control image is-64x64">
                  <img
                    alt="aoshang logo"
                    src="../assets/logo-aoshang.png"
                  /><br />
                </figure>
                   <b-button
            type="is-small"
            class="gap"
            icon-left="thumbs-up"
            icon-pack="fas" style="margin-top:5px;"
            >Like</b-button>
       
    </div>
    <div class="media-content">
      <div class="content">
        <p>
            <b-input
                      v-model="mobile"
                      placeholder="+853"
                      icon="mobile"
                      icon-pack="fas"
                      size="is-medium"
                    ></b-input>  <b-button
                      type="is-primary is-light"
                      @click="submitMobile()"
                      message="error"

                      expanded style="margin-top:5px"
                      >取得最新美食優惠</b-button>
       </p>

      </div>
      
    </div>
  </article>
  
</div>
   
          <div class="container" style="margin-top:20px;">
    
 <b-button
            class="gap button is-success"
            @click="surfNet(2)"
            message="error"
            icon-left="wifi"
            icon-pack="fas" 
            expanded v-bind:loading="wifi_loading" 
            >高速 WiFi</b-button
          >
          <span style="opacity:0.8;font-size:9px" @click="changeShowI()">power by DOT WiFi</span>
            <div class="container gap"></div>
            <div class="container"></div>
            <!-- <h1 class="title">取得最新優惠</h1>
          <h2 class="subtitle gap">Latest promotion</h2> -->
            <!-- <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fmacaureward&width=157&layout=button_count&action=like&size=large&share=true&height=46&appId=150051275074770"
            width="157"
            height="46"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe> -->
          </div>
        </div>
        
      </div>

      <!-- <span v-if="client.facebookLikeCode" v-html="client.facebookLikeCode"></span>  -->
      <p v-if="client.tagline">{{ client.tagline }}</p>
    
   
    </div>

    <section class="hero is-medium is-primary is-bold">
      <div class="hero-body"></div>
    </section>
       <footer>
      <div class="content has-text-centered">
        <strong>DOT-WIFI</strong> by
        <a href="https://wizmacau.com">WIZ</a>
        <!-- <div v-if="debug">{{ debug }}</div> -->
        <!-- <a href="http://facebook.com">FB</a> -->
        <!-- <div v-if="debug2">{{ debug2 }}</div> -->
      </div>
    </footer> 
 
  </div>
</template>

<script>
import "../../node_modules/@fortawesome/fontawesome-free/js/all.js";
import { isMobile,deviceDetect,isIOS,isAndroid, deviceType } from 'mobile-device-detect';
import { toHumanString} from 'human-readable-numbers'
import { apiSubmitMobile, apiSubmitStat, getSite } from "./../service";

import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Vue from "vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

console.log( toHumanString(120000) );

Vue.filter('toHumanString', function (value) {
  return toHumanString(value)
})


// mac	
// object (DeviceLogsMacProperty)
// hash	
// object (DeviceLogsHashProperty)
// name	
// object (DeviceLogsNameProperty)
// userAgent	
// object (DeviceLogsUserAgentProperty)
// deviceType	
// object (DeviceLogsDeviceTypeProperty)
// os	
// object (DeviceLogsOsProperty)
// version


//library.add(faMobile, faAngleLeft, faAngleRight,faThumbsUp,faHome);
library.add(fas);
config.autoAddCss = true;

Vue.component("vue-fontawesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome-icon",
  defaultIconPack: "fas",
});

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

const deviceUUID= function (){
    let ID = function () {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return '_' + Math.random().toString(36).substr(2, 9);
    };
    let uuid = localStorage.getItem('uuid',false);
    if(!uuid){
      uuid = ID();
      localStorage.setItem('uuid',uuid);
    }
    return uuid;
  };

export default {
  name: "ClientPortal",
  props: {
    msg: String,
    result: String,
    error: String,
  },
  data() {
    return {
      wifi_loading: false,
      like_loading:false,
      show_i : 2,
      demo: false,
      client: false,
      mobile: "",
      carousels: [
        { text: "Slide 1", color: "primary", image: "banner-brewlab-1" },
        { text: "Slide 2", color: "info" , image: "banner-brewlab-2" },
        { text: "Slide 3", color: "success", image: "banner-brewlab-3"  },
        // { text: "Slide 4", color: "warning" },
        // { text: "Slide 5", color: "danger" },
      ],
      debug: window.location,
      debug2: this.$route.query.auth,
      debug3: this.$route.query.mac,
    };
  },
  created: function () {
    this.loadSite(this.$route.params.slug);
  },
  methods: {
      changeShowI(){
    this.show_i =(this.show_i +1 )%3;
  },
  hasSetting:function(s,defaultValue){
    return this.client && this.client.setting && this.client.setting[s] ? this.client.setting[s] : defaultValue;
  },
    getImgUrl(pet) {
      if(pet && pet.indexOf('http')===0 ){
        return pet
      }
      // var images = require.context('../assets/', false, /\.png$/)
      // return images('./' + pet + ".png")
    },
    surfNet: function (i) {
      let fbBtn = false;
      let wifiBtn = false;
      if(i==1){
        fbBtn=true;
      }
      if(fbBtn){
        this.like_loading = true;
      }
      if(i==2){
        wifiBtn=true;
      }
      if(wifiBtn){
        this.wifi_loading = true;
      }
      var JSONP = document.createElement("iframe");
      JSONP.classList.add('hideme');

      JSONP.src = this.$route.query.auth;
      document.getElementsByTagName("body")[0].appendChild(JSONP);
      setTimeout(() => {
          document.getElementsByTagName("body")[0].removeChild(JSONP)
      },100)

      if(fbBtn){
        setTimeout(() => {
          if(isMobile){
            if(isIOS){
              window.location = this.client.iosFacebookOpen;
            }else if( isAndroid){
              window.location = this.client.androidFacebookOpen;
            }else{
              window.location = this.client.facebookPage;
            }
          }else{
            window.location = this.client.facebookPage;
          }
        },500)
      }
      

      this.debug2 = this.$route.query.auth;
    },
    loadSite: async function (slug) {
      let site = await getSite(slug);
      site = Object.assign({},site);
      site.backgroundClass = { backgroundColor : site.mainBackgroundColor }
      this.carousels = site.banners.map( v => ({text:v, image:v}) )
      console.log({ site});
      this.client = site;

      let d = deviceDetect();
      let ua = ""+ d.userAgent;
      delete d.userAgent;
      let mydevice = {
        "mac": this.$route.query.mac,
        "hash" : deviceUUID(),
        "name" : "",
        "userAgent" : ua,
        "deviceType": deviceType ,
        "os" : d.osName,
        "version" : d.osVersion,
        "deviceInfo": d,
        "client":slug
      }
      await apiSubmitStat(mydevice);
      console.log({d,mydevice});

    },
    submitMobile: async function () {
      try {
        let ret = await apiSubmitMobile(this.mobile, true);
        console.log({ ret });
        if (ret.created) {
          this.$buefy.notification.open({
            message: `成功！`,
            type: "is-success",
            position: "is-top",
            hasIcon: false,
          });
        } else {
          //   let sample = {
          //     "message": "Validation error",
          //     "traceId": "|d31b67b6-4576796fb1aba6ca.",
          //     "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
          //     "details": ["mobile: Invalid Macau Mobile", "mobile: Must have exactly 8 character(s)."],
          //     "statusCode": 400
          // };
          if (ret.message == "Validation error") {
            this.$buefy.notification.open({
              message: `請輸入正确手提號碼`,
              type: "is-danger",
              position: "is-top",
              hasIcon: true,
            });
          }
        }
      } catch (e) {
        this.$buefy.notification.open({
          message: `error: ${e}`,
          type: "is-danger",
          position: "is-top",
          hasIcon: true,
        });
      }
    },
  },
};
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hello {
  text-align: center;
}

.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.8;
}

.hideme{
  display:none;
}


/* .hero.is-primary.is-bold {
    background-image: linear-gradient(141deg, white 0%, #EB6CFE 71%, #EB6CFE 100%);
} */
</style>

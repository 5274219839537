<template>
  <section class="section">
    <div v-if="withLink">
      <router-link
        :to="{ name: 'blog', params: { slug: post.slug, id: post.id } }"
      >
        <h1>{{ post.title }}</h1>
      </router-link>
    </div>
    <div v-else>
      <h2>{{ post.title }}</h2>
    </div>

    <div v-html="post.text"></div>
  </section>
</template>

<script>
export default {
  name: "Post",
  props: {
    post: Object,
    withLink: Boolean,
  },
};
</script>

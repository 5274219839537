<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ page.title }}</h1>
      <div v-html="page.text"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Page",
  props: {
    page: Object,
  },
};
</script>


import {CONFIG} from './config'
import {clearBearerToken, fetchBearerToken, buildUrl} from './squidex'


export {fetchBearerToken} from './squidex'


function lang(d,key,default_value){
    const langs = ['zh-HK','en','iv'];
    if(!d){
        return default_value;
    }
    for(let ll in langs){
        let l = langs[ll]
        if( typeof d[l] !== 'undefined' ){
            return d[l];
        }
    }
    if(typeof default_value !== 'undefined' ){
        return default_value;
    }
    throw new Error(`lang fail! for ${key}`);
}

////////// submit mobile

export async function apiSubmitMobile(mobile,retry){
    const url = 'mobile'
    const token = await fetchBearerToken();
    let data = {mobile:{iv:mobile},ip:{iv:'127.0.0.1'}}
    const response = await fetch(buildContentCreateUrl(url), {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data) 
    });     
    
    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return apiSubmitMobile(mobile);
            }
        }

        let e = await response.json();
        return e;
    }

    return await response.json();
}


////////// submit mobile

export async function apiSubmitStat(stat,retry){
    const url = 'device-logs'
    const token = await fetchBearerToken();
    let data = {client:{iv:stat.client},mac:{iv:stat.mac},hash:{iv:stat.hash},name:{iv:stat.name},userAgent:{iv:stat.userAgent},deviceType:{iv:stat.deviceType},version:{iv:stat.version},os:{iv:stat.os}, deviceInfo:{iv:stat.deviceInfo} }
    console.log({data})
    const response = await fetch(buildContentCreateUrl(url), {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data) 
    });     
    
    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return apiSubmitStat(stat);
            }
        }

        let e = await response.json();
        return e;
    }

    return await response.json();
}

/////////// url builder

function buildContentCreateUrl(url) {
    if (url.length > 0 && url.startsWith('/')) {
        url = url.substr(1);
    }

    const result = `${CONFIG.url}/api/content/${CONFIG.appName}/${url}/?publish=true`;

    return result;
}



///////////// page and posts
export function getAsset(idOrSlug) {
   
    const ans = idOrSlug && idOrSlug[0]=='.' ? idOrSlug : buildUrl(`api/assets/${CONFIG.appName}/${idOrSlug}`)
    console.log({idOrSlug,ans})
    return ans
}

export async function getSite(slug,preload) {
    if(!preload){
        const json = await getContent(`api/content/${CONFIG.appName}/site?$filter=data/slug/iv eq '${slug}'`);

        const { items } = json;

        if (items.length === 0) {
            return null;
        }
        return parseSite(items[0]);
    }else{
        return parseSite(preload);
    }
     
}

export async function getPost(id) {
    const json = await getContent(`api/content/${CONFIG.appName}/posts/${id}`);

    return parsePost(json);
}

export async function getPage(slug) {
    const json = await getContent(`api/content/${CONFIG.appName}/pages/?$filter=data/slug/iv eq '${slug}'`);

    const { items } = json;

    if (items.length === 0) {
        return null;
    }

    return parsePage(items[0]);
}

export async function getPosts() {
    const json = await getContent(`api/content/${CONFIG.appName}/posts`);

    const { total, items } = json;

    return { total, posts: items.map(x => parsePost(x)) };
}

export async function getPages() {
    const json = await getContent(`api/content/${CONFIG.appName}/pages`);

    const { total, items } = json;

    return { total, pages: items.map(x => parsePage(x)) };
}

function parseSite(response) {
    let data = response.data;
    let ret= {
        id: response.id,
        slug: data.slug.iv,
        logo: data.logo.iv && data.logo.iv.length ? data.logo.iv.map(getAsset)[0] : null,
        banners: data.banners.iv && data.banners.iv.length ? data.banners.iv.map(getAsset) : [],
        heroBackground: data.heroBackground.iv && data.heroBackground.iv.length ? data.heroBackground.iv.map(getAsset)[0] : null,
        title: lang(data.title,'site title',false), 
        facebookLikeCount: lang(data.facebookLikeCount,'site facebookLikeCount',false), 
        mainBackgroundColor: data.mainBackgroundColor.iv,
        tagline: lang(data.tagline,'site tagline',false),
        facebookPage: lang(data.facebookPage,'site facebookPage',false),
        iosFacebookOpen: lang(data.iosFacebookOpen,'site iosFacebookOpen',false),
        androidFacebookOpen: lang(data.androidFacebookOpen,'site iosFacebookOpen',false),
        setting: lang(data.setting,'site setting',false),
    };
    console.log({parseSite:ret});
    return ret;
}

function parsePost(response) {
    return {
        id: response.id,
        title: response.data.title.iv,
        text: response.data.text.iv,
        slug: response.data.slug.iv
    };
}

function parsePage(response) {
    return {
        id: response.id,
        title: response.data.title.iv,
        text: response.data.text.iv,
        slug: response.data.slug.iv
    };
}

function getContent(url) {
    return getContentInternal(url, true);
}

async function getContentInternal(url, retry) {
    // Fetch the bearer token.
    const token = await fetchBearerToken();

    const response = await fetch(buildUrl(url), {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });     

    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return getContentInternal(url);
            }
        }

        throw new Error(`Failed to retrieve content, got ${response.statusText}`);
    }

    return await response.json();
}
<template>
  <div id="app">
   
        <TopNav v-if="$route.name!='client'"/>
        <div class="container body-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TopNav from "./components/TopNav.vue"

export default {
  name: 'App',
  components: {
    TopNav
  },
  compiled() {
    
  },
  data() {
      return {
          showNav: true,
          // more..
      }
  }
}
</script>

<style>
#app {

}
.gap{
  margin-bottom:5px;
}
</style>
